<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="content d-flex flex-column flex-lg-row mb-16">
      <div class="left ml-lg-8 mr-lg-5">
        <div class="PromoImage">
          <img src="@/assets/promoImage/image_photography.png" alt="" />
        </div>
        <div class="timer d-flex flex-lg-row" v-if="!isPhotographyEnd">
          <div class="days">
              <div class="top">
                 <span class="mr-1">{{d1}}</span>
                 <span class="mr-2">{{d2}}</span>
              </div>
              <div class="bom">
                  DAY
              </div>
          </div>
          <div class="mao">:</div> 
          <div class="hours">
              <div class="top">
                 <span class="mr-1 ml-2">{{h1}}</span>
                 <span class="mr-2">{{h2}}</span>
              </div>
              <div class="bom">
                  HR
              </div>
          </div> 
          <div class="mao">:</div> 
          <div class="minutes">
              <div class="top">
                 <span class="ml-2 mr-1">{{m1}}</span>
                 <span class="mr-2">{{m2}}</span>
              </div>
              <div class="bom">
                  MIN
              </div>
          </div> 
          <div class="mao">:</div> 
          <div class="seconds">
              <div class="top">
                 <span class="ml-2 mr-1">{{s1}}</span>
                 <span class="mr-2">{{s2}}</span>
              </div>
              <div class="bom">
                  SEC
              </div>
          </div> 
        </div>
        <div v-else class="  endDesc">
            <span> The contest has ended at 4th March 12:00 (UTC+8). According to the snapshot, the result will be post via Twitter.</span>
        </div>
        <!-- <div class="btn mt-6">
          <button @click="toContest" class="claim">Go to contest</button>
        </div> -->
      </div>
      <div class="right mt-lg-0 mt-9">
        <div class="title mt-2 mb-lg-6">
          <span>Uptick NFT & IRISnet Photography Competition</span>
        </div>
        <div class="desc mb-lg-6">
          <p>
            Uptick Network is excited to announce our joint NFT photography
            competition with IRIS Network. We’re always on the lookout for
            emerging talent for the platform, and we feel photography NFTs are
            an important category on the rise that should be nurtured
            appropriately.
          </p>
        </div>
        <div class="claimtime mb-lg-6">
          <div>Time：</div>
          <div>18th February to 4th March at 12:00 (UTC+8)</div>
        </div>
        <div class="prize">
          <div>Prizes：</div>
          <div class="mb-lg-6">
            <div>
              Users can vote on their favourite NFT entry by pressing the
              ‘Heart’ button on the listing page, top three with the most likes
              will awarded the Prizes:
            </div>
            <ul>
              <li>1st place: 25,000 IRIS</li>
              <li>2nd place: 15,000 IRIS</li>
              <li>3rd place: 10,000 IRIS</li>
            </ul>
            <div>(All prizes split 50/50 between Creator/Voters)</div>
          </div>
          <div>
            <div>How to enter the competition?</div>
            <div>1) Mint an original 1/1 photograph on UptickNFT.com</div>
            <div>
              2) Follow @uptickproject + @upticknft + @IRISnetwork on Twitter,
              join the Uptick
              <a target="blank" href="https://t.me/uptickproject">Telegram</a>
              and
              <a target="blank" href="https://discord.com/invite/teqX78VZUV"
                >Discord</a
              >
            </div>
            <div>
              3) Tag the Uptick Network
              <a target="blank" href="https://twitter.com/uptickproject"
                >Twitter</a
              >
              account with a link to your NFT, along with a picture - making
              sure to use the hashtag #UptickPhoto
            </div>
            <div>
              4) Retweet the competition
              <a
                target="blank"
                href="https://twitter.com/uptickproject/status/1494686220181553154?s=20&t=KrfKZEglhtbS3PsBlyKTSw"
                >tweet</a
              >
              and invite your supporters to hit the ‘Heart’ button on your
              entry!
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      d1:'',
      d2:'',
      h1:'',
      h2:'',
      m1:'',
      m2:'',
      s1:'',
      s2:'',
      isPhotographyEnd:false
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
   mounted() {
     this.Time();
     var timestamp = Date.parse(new Date()).toString();
      let nowTimeStamp = timestamp.substr(0, 10);
      // 摄像作品结束时间
      let photographyEndTimeStamp = 1646366400;
        if (nowTimeStamp > photographyEndTimeStamp) {
        this.isPhotographyEnd = true;
        console.log(nowTimeStamp);
        console.log(photographyEndTimeStamp);
        console.log("结束");
      } else {
        this.isPhotographyEnd = false;
        console.log(nowTimeStamp);
        console.log(photographyEndTimeStamp);
        console.log("开始");
      }

   },
  methods: {
    toContest() {
      this.$router.push({ name: "Competition" });
    },
    getTime() {
      // 摄像作品结束时间
      let photographyEndTimeStamp = 1646366400000;
       this.now = new Date().valueOf()    
        this.end = photographyEndTimeStamp;

          if (this.now < this.end) {
            this.seconds = (this.end - this.now) / 1000;

          }else{
            console.log('活动结束')
          }
     },
      // 天 时 分 秒 格式化函数
      countDown() {
        let d = parseInt(this.seconds / (24 * 60 * 60));
        let day =  d < 10 ? "0" + d :  d;
        this.d1 = String(day).split("")[0]
        this.d2 = String(day).split("")[1]
        //let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
        let h = parseInt((this.seconds / (60 * 60)) % 24);
        let hour = h < 10 ? "0" + h : h;
        this.h1 = String(hour).split("")[0]
        this.h2 = String(hour).split("")[1]
        let m = parseInt((this.seconds / 60) % 60);
        let minutes = m < 10 ? "0" + m : m;
        this.m1 = String(minutes).split('')[0];
        this.m2 = String(minutes).split('')[1];
        let s = parseInt(this.seconds % 60);
        let second = s < 10 ? "0" + s : s;
        this.s1 = String(second).split("")[0]
        this.s2 = String(second).split("")[1]
      },
      //定时器没过1秒参数减1
      Time() {
        setInterval(() => {
          if (this.seconds > 0) {
            this.seconds -= 1;
            this.countDown();
          } else {
            this.getTime();
          }
        }, 1000);
      },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  .content {
    margin-top: 50px;
    .left {
      width: 560px;
      // height: 550px;
      img {
        width: 560px;
        height: 550px;
        display: block;
        object-fit: contain;
        cursor: pointer;
      }
      .timer{
        // max-width:305px;
        // margin:17px auto 21px;
		justify-content: center;
		        margin-top:17px;
        .mao{
          
          font-family:Helvetica;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: 0px;
          color: #270645;
        }
        .bom{
          font-family:Helvetica;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #766983;
          text-align:center;
          margin-top:7px;
        }
        span{
          width:27px;
          height:36px;
          display:inline-block;
          text-align:center;
          line-height:36px;
          background-color: #270645;
	        border-radius: 5px;
          font-family:Helvetica;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
      .endDesc{
        width: 410px;
        margin-left: 71px;
        margin-top:20px;
         color: red;
      }
      .btn {
        width: 276px;
        height: 51px;
        background-image: linear-gradient(#270645, #270645),
          linear-gradient(
            90deg,
            #d300fd 0%,
            #a439fe 26%,
            #7471ff 52%,
            #00fdcf 100%
          ),
          linear-gradient(#270645, #270645);
        background-blend-mode: normal, normal, normal;
        border-radius: 25px;
        text-align: center;
        margin: 0 auto;

        .claim {
          font-family:Helvetica;
          font-size: 23px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 51px;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }
    .right {
      width: 588px;
      // height: 613px;
      .title {
        span {
           font-family: Helvetica;
          font-size: 45px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 70px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .desc {
        p {
          max-width: 567px;
          font-family:Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 23px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .claimtime {
        div {
          font-family:Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 18px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .prize {
        max-width: 578px;
        height: 345px;
        font-family:Helvetica;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 25px;
        letter-spacing: 0px;
        color: #270645;
      }
    }
  }
  &.mobile {
    .content {
      margin-top: 30px;
      .left {
        max-width: 100%;
        min-height: 437px;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: contain;
          cursor: pointer;
        }
        .timer{
          // width:90%;
          // margin-left:14%;
          	justify-content: center;
		        margin-top:17px;
          span{
            width:20px;
            height:30px;
            font-size:15px;
            line-height:30px;
          }
        }
        .endDesc{
        width: 340px;
        margin-left: 7px;
        margin-top:20px;
        color: red;
      }
        .btn {
          width: 80%;
          .claim {
            width: 80%;
          }
        }
      }
      .right {
        max-width: 100%;
        min-height: 613px;
        .title {
          margin-bottom: 34px;
          span {
            font-size: 25px;
            line-height: 40px;
          }
        }
        .desc {
          // max-width:576px;
          margin-bottom: 28px;
        }
        .prize{
           min-height: 420px;
        }
        .claimtime {
          margin-bottom: 34px;
        }
      }
    }
  }
}
</style>
